$( document ).ready(function() {

if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
 var mobile = true;
}

else {
  var mobile = false;
}

// VARS
// var vimeoPlayer = require ('@vimeo/player');
// var jQuery = require('jquery');
var pjax = require('jquery-pjax');
// var carousel = require('slick-carousel');
var body = $('body');
var iContainer = $('#i-container');
var headerContainer = $('#header-container');
var footerNext = $('.footer');
var iframe = $('iframe.vimeo');
if ( $(body).data('template') == 'video' ){
  var player = new Vimeo.Player(iframe);
}
else var player = '';

// FUNCTIONS

function headerHover() {
  $('#header-container').hover( function() {
    $('#i-container').addClass('hover');
    $('#header-container').addClass('hover');
    $('section.meta-content').addClass('hover');
  }, function() {
    $('#i-container').removeClass('hover');
    $('#header-container').removeClass('hover');
    $('section.meta-content').removeClass('hover');
  });
}

function gallery() {
  if($('body').data('template') !== 'video') {

    $('.text-content .thumbnail-grid').slickLightbox({
      itemSelector: 'figure a',
      background: 'black',
      caption: 'caption',
      layouts: {
        closeButton: '<button type="button" class="modal-close"></button>'
      },
      slick: {
        prevArrow: '<div class="modal-previous"></div>',
        nextArrow: '<div class="modal-next"></div>',
      },
      'show.slickLightbox': function(){
      }
    });
    $('.text-content figure.lead').slickLightbox({
      itemSelector: 'a',
      background: 'black',
      caption: 'caption',
      layouts: {
        closeButton: '<button type="button" class="modal-close"></button>'
      },
      slick: {
        prevArrow: '<div class="modal-previous"></div>',
        nextArrow: '<div class="modal-next"></div>',
      },
      'show.slickLightbox': function(){
      }
    });
  }
}

function scrollarticle() {
    // console.log('articllelleel');
    $(window).on('scroll', function() {
      var scrollTop = $(this).scrollTop();
      if ($(window).scrollTop() >= $(document).height() - $(window).height() - 150) {
        $('.footer').addClass('show');
      }
      else {
        $('.footer').removeClass('show');
      }
      if(body.data('template') !== 'home') {
        if($(window).scrollTop() >= 50) {
          $('body').addClass('minimize');
        }
        else if($(window).scrollTop() >= 1) {
          $('body').removeClass('minimize');
        }
      }
    });
    headerHover();
    if(!mobile) {
      var lastScrollTop = 0;
      window.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
         var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
         if (st > lastScrollTop){
            // downscroll code
         } else {
           $('body').removeClass('minimize');
         }
         lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      }, false);
    }
    new ClipboardJS('a.button.copy');
    $('a.button.copy').click(function(event) {
      event.preventDefault();
      $(this).html('Copied');
    });
  // }
}

function scrollhome() {
  if(body.data('template') == 'home') {
    $(window).on('scroll', function() {
      var scrollTop = $(this).scrollTop();
      if($(window).scrollTop() >= 100) {
        $('body').addClass('minimize');
      }
      else if($(window).scrollTop() >= 1) {
        $('body').removeClass('minimize');
      }
    });
    if(mobile) {
      $('#container-left .description').append($('.info'));
      $('.conversations').append($('#container-left .sponsors'));
    }
  }
}

function vimeo() {
  $('figure.video').click(function() {
    console.log('click');
    $(this).toggleClass('active');
    player.play().then(function() {
    });
  });
}

function timeline() {
  $('module.timeline .carousel h2').each(function(){
    $(this).next('p').andSelf().wrapAll('<div class="slide">');
  });
  $('module.timeline .carousel').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: '.previous',
    nextArrow: '.next',
  });
  var timelineHeight = $('module.timeline .carousel').height() + $('module.timeline h1').height() * 1.5 + 'px';
  $('module.timeline').css('min-height', timelineHeight);
}

function smoothscroll() {
  $('a[href*="#"]:not([href="#"])').click(function() {
      var top = $('#i-container .border.top').css('margin-top');
      var topInt = 0 - parseInt(top) * 3;
      var offset = topInt;
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
              $('html, body').animate({
                  scrollTop: target.offset().top + offset
              }, 1200);
              return false;
          }
      }
    $('body').addClass('minimize');
    setTimeout(function() {
      $('body').addClass('minimize');
    }, 1200);
  });
}

gallery();
scrollarticle();
scrollhome();
vimeo();
timeline();
smoothscroll();

// PJAX

$(document).pjax('a[data-pjax]', '#body-wrapper', {fragment: '#body-wrapper'});

$.pjax.defaults.timeout = 1200;

$(document).on('pjax:start', function() {
  console.log('start');
  iContainer.removeClass();
  $('#body-wrapper').addClass('hide');
});

$(document).on('pjax:success', function() {
  console.log('success');
  scrollarticle();
});

$(document).on('pjax:end', function() {
  console.log('end');
  headerHover();
  var template = $('#template').data('template');
  if( template == null ) {
    var template = 'default';
  }
  $('#body-wrapper').removeClass();
  setTimeout(function() {
    body.attr('data-template', template);
    scrollhome();
    vimeo();
    timeline();
    smoothscroll();
  }, 0);
  setTimeout(function() {
    $('#body-wrapper').addClass('loaded');
    gallery();
    if ( $('body').data('template') == 'home' ){
      $('body').removeClass('minimize');
    }
    if ( $('body').data('template') == 'video' ){
      var iframe = $('iframe');
      var player = new Vimeo.Player(iframe);
    }
    else var player = '';
  }, 200);
});

setTimeout(function() {
  $('#body-wrapper').addClass('loaded');
}, 100);

if(mobile && $('body').data('template') == 'home') {
  $('#container-left .description').append($('.info'));
  $('.conversations').append($('#container-left .sponsors'));
}

window.addEventListener("resize", function() {
  var timelineHeight = $('module.timeline .carousel').height() + $('module.timeline h1').height() * 1.5 + 'px';
  $('module.timeline').css('min-height', timelineHeight);
});

});
